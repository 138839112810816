<template>
  <b-container class="activity-table">
    <h1 class="my-4">{{ title }}</h1>
    <div class="content">
      <h4 class="my-4 orange-text">{{ $t('填写表格') }}</h4>
      <b-card>
        <b-form-group
          :label="`${$t('中文姓名')}`"
          label-for="chinesename"
          label-cols-sm="4"
          label-cols-lg="2"
          class="_required"
        >
          <b-form-select
            id="chinesename"
            v-model="form.nameZh"
            :options="nameZhList"
            name="chinesename"
            v-validate="'required'"
            :class="errors.has('nameZh') && 'is-invalid'"
          ></b-form-select>
          <b-form-invalid-feedback :state="errors.has('chinesename')">
            {{ errors.first('chinesename') }}
          </b-form-invalid-feedback>
        </b-form-group>

        <b-form-group
          :label="`${$t('外文姓名')}`"
          label-for="foreignname"
          label-cols-sm="4"
          label-cols-lg="2"
          class="_required"
          :class="errors.has('foreignname') && 'is-invalid'"
        >
          <b-form-select
            id="foreignname"
            v-model="form.nameEn"
            :options="nameEnList"
            name="foreignname"
            v-validate="'required'"
            :class="errors.has('foreignname') && 'is-invalid'"
          ></b-form-select>
          <b-form-invalid-feedback :state="errors.has('foreignname')">
            {{ errors.first('foreignname') }}
          </b-form-invalid-feedback>
        </b-form-group>

        <b-form-group
          :label="`${$t('性別')}`"
          label-for="gender"
          label-cols-sm="4"
          label-cols-lg="2"
          class="_required align-items-center"
        >
          <b-form-radio-group v-model="form.gender" id="gender" name="gender">
            <b-form-radio value="male" active>{{ $t('男') }}</b-form-radio>
            <b-form-radio value="female">{{ $t('女') }}</b-form-radio>
          </b-form-radio-group>
          <b-form-invalid-feedback :state="errors.has('gender')">
            {{ errors.first('gender') }}
          </b-form-invalid-feedback>
        </b-form-group>

        <b-form-group
          :label="`${$t('出生日期')}`"
          label-for="birthday"
          label-cols-sm="4"
          label-cols-lg="2"
          class="_required"
        >
          <!-- <b-form-datepicker
            id="birthday"
            :placeholder="`${$t('選擇日期')}`"
            v-validate="'required'"
            v-model="form.birthday"
            class="mb-2"
            right
            name="birthday"
            :date-format-options="{
              year: 'numeric',
              month: '2-digit',
              day: '2-digit'
            }"
            :class="errors.has('birthday') && 'is-invalid'"
          /> -->
          <DatePicker
            v-validate="'required'"
            v-model="form.birthday"
            class="mb-2"
            right
            :placeholder="`${$t('選擇日期')}`"
            name="birthday"
            :class="['w-100', errors.has('birthday') && 'is-invalid']"
            :show-today="false"
            format="YYYY-MM-DD"
            :disabled-date="disabledDate"
            size="large"
            :defaultPickerValue="currentDay"
          />
          <b-form-invalid-feedback :state="errors.has('birthday')">
            {{ errors.first('birthday') }}
          </b-form-invalid-feedback>
        </b-form-group>

        <div class="d-flex justify-content-between">
          <b-form-group
            :label="`${$t('聯絡電話')}`"
            label-for="region"
            label-cols-sm="8"
            label-cols-lg="4"
            class="col-md-6 p-0 _required"
          >
            <b-form-select
              id="region"
              v-model="form.region"
              :options="regionList"
              name="region"
              v-validate="'required'"
              :class="errors.has('region') && 'is-invalid'"
            ></b-form-select>
            <b-form-invalid-feedback :state="errors.has('region')">
              {{ errors.first('region') }}
            </b-form-invalid-feedback>
          </b-form-group>

          <b-form-group label-for="phone" label-cols-sm="8" label-cols-lg="2" class="col-md-6 p-0">
            <b-form-input
              id="phone"
              v-model="form.phone"
              v-validate="'required|phone'"
              type="tel"
              :placeholder="`${$t('無需輸入區號')}`"
              name="phone"
              :class="errors.has('phone') && 'is-invalid'"
            ></b-form-input>
            <b-form-invalid-feedback :state="errors.has('phone')">
              {{ errors.first('phone') }}
            </b-form-invalid-feedback>
          </b-form-group>
        </div>

        <b-form-group
          :label="`${$t('電郵地址')}`"
          label-for="email"
          label-cols-sm="4"
          label-cols-lg="2"
          class="_required"
        >
          <b-form-input
            id="email"
            v-model="form.email"
            type="email"
            v-validate="'required|email'"
            name="email"
            :placeholder="`${$t('請輸入電郵地址')}`"
            :class="errors.has('email') && 'is-invalid'"
          ></b-form-input>
          <b-form-invalid-feedback :state="errors.has('email')">
            {{ errors.first('email') }}
          </b-form-invalid-feedback>
        </b-form-group>
      </b-card>
      <b-row class="btn-box my-4" align-h="center">
        <b-button class="btn" pill>
          {{ $t('提交表格') }}
        </b-button>
        <b-button class="btn reset-btn" @click="reset" pill>
          {{ $t('重置') }}
        </b-button>
      </b-row>
    </div>
  </b-container>
</template>
<script>
import { DatePicker } from 'ant-design-vue'
import moment from 'moment'
import { mapState } from 'vuex'
import updateSeoInfo from '@/utils/meta'
import Lifecycle from '@/mixins/lifecycle'
export default {
  components: {
    DatePicker
  },
  mixins: [Lifecycle],
  data() {
    return {
      form: {
        nameZh: null,
        nameEn: null,
        gender: 'male',
        birthday: '',
        region: null,
        phone: '',
        email: ''
      },
      regionList: [{ text: this.$t('請選擇'), value: null, disabled: true }],
      nameZhList: [{ text: this.$t('請選擇'), value: null, disabled: true }],
      nameEnList: [{ text: this.$t('按證件填寫'), value: null, disabled: true }]
    }
  },
  serverPrefetch() {
    return this.fetchData()
  },
  computed: {
    currentDay() {
      return moment().subtract(1, 'days')
    },
    title() {
      return this.$route.params.title
    }
  },
  mounted() {
    this.fetchData()
  },
  methods: {
    reset() {
      this.form = {
        nameZh: null,
        nameEn: null,
        gender: 'male',
        birthday: '',
        region: null,
        phone: '',
        email: ''
      }
    },
    disabledDate(current) {
      return current && current < moment().subtract(1, 'days')
    },
    fetchData() {
      let path = this.$route.path.replace(`/${this.$route.params.lang}/`, '')
      path = path.replace(`preview/`, '')
      const cookies = this.$ssrContext && this.$ssrContext.cookies
      const { preview, id } = this.$route.query
      return new Promise((resolve, reject) => {
        Promise.all([
          this.fetchInitData(),
          this.$store.dispatch('common/FETCH_PAGE', {
            path,
            cookies,
            preview,
            id
          })
        ])
          .finally(() => {
            this.hanldeInit()
            resolve(updateSeoInfo.call(this))
          })
          .catch(reject)
      })
    },
    hanldeInit() {}
  }
}
</script>
